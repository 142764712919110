<template>
  <div class="custom-navi" style="padding-bottom: 70px;">
   
    <a-menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      mode="inline"
    >
      <!-- <a-menu-item key="home" v-if="!isProd">
        <template #icon>
          <img class="navi-icon" src="@/assets/images/dashboard.png" alt="">
        </template>
          <router-link :to="{ path: '/keywords/home'}">
            首页
          </router-link>
      </a-menu-item> -->
      <a-sub-menu key="basic">
        <template #icon>
          <img class="navi-icon" src="@/assets/images/user-manager.png" alt="">
        </template>
        <template #title>用户管理</template>
        <a-menu-item key="/keywords/profile">
            <router-link :to="{ path: '/keywords/profile'}">
              个人设置
            </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/users">
            <router-link :to="{ path: '/keywords/users'}">
              博主管理
            </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/directors" v-if="[ UserRoleDirectorManager ].indexOf(user_role) > -1">
            <router-link :to="{ path: '/keywords/directors'}">
              团长管理
            </router-link>
        </a-menu-item>
      </a-sub-menu>

      <a-menu-item key="/keywords/apply">
        <template #icon>
          <img class="navi-icon" src="@/assets/images/favorite-chart.png" alt="">
        </template>
          <router-link :to="{ path: '/keywords/apply'}">
            推广中心
          </router-link>
      </a-menu-item>

      <a-sub-menu key="stats" >
        <template #icon>
          <img class="navi-icon" src="@/assets/images/status-up.png" alt="">
        </template>
        <template #title>数据中心</template>
         <a-menu-item  key="/keywords/stats_keyword">
            <router-link :to="{ path: '/keywords/stats_keyword'}">
              关键词每日数据
            </router-link>
        </a-menu-item>
        <a-menu-item  key="/keywords/stats_keyword_sum">
            <router-link :to="{ path: '/keywords/stats_keyword_sum'}">
              关键词累计数据
            </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/stats_data_blogger">
            <router-link :to="{ path: '/keywords/stats_data_blogger'}">
              博主累计数据
            </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/stats_newer_platform_sum">
          <router-link :to="{ path: '/keywords/stats_newer_platform_sum'}">
            平台累计数据
          </router-link>
        </a-menu-item>
        <a-menu-item v-if="[ UserRoleDirectorManager ].indexOf(user_role) > -1"  key="/keywords/stats_data_director_daily">
            <router-link :to="{ path: '/keywords/stats_data_director_daily'}">
              团长每日统计
            </router-link>
        </a-menu-item>
        <a-menu-item v-if="[ UserRoleDirectorManager ].indexOf(user_role) > -1"  key="/keywords/stats_data_director">
            <router-link :to="{ path: '/keywords/stats_data_director'}">
              团长数据统计
            </router-link>
        </a-menu-item>
        <a-menu-item key="/keywords/settlement">
          <router-link :to="{ path: '/keywords/settlement'}">
            结算数据
          </router-link>
        </a-menu-item>
      
      </a-sub-menu>
    
    </a-menu>
  </div>
</template>
<script lang='ts' setup>
import { defineComponent, reactive, toRefs, watch, ref, onMounted, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import {  
  UserStatusMap,
  UserRoleOperator,
  UserRoleDirectorManager,
  UserRoleDirector,
  UserRoleBlogger,
  UserRoleAuditor,
} from '@/utils/macro'


const isProd = ref(window.location.hostname=='koc.marsvis.com')

const store = useStore()

const user_role = computed(() => store.state.user.role)

const router = useRouter()
const route = useRoute()

console.log('route.meta.key', route.meta.key);

const openKeys = ref([])
const selectedKeys = ref([])

onMounted(() => {
  selectedKeys.value = [route.path]
  console.log('route', route);
  
  openKeys.value = [route.meta.key]
})

</script>
<style lang="scss">
.custom-navi {
  .navi-icon {
    width: 16px;
  }
  .ant-menu-light {
    color: $blackText;
    font-weight: 600;
    font-size: 14px;
  }
  .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    font-weight: normal;
    background-color: #fff;
    color: $greyText;
  }
  .ant-menu-light .ant-menu-sub .ant-menu-item-selected {
    color: $mainColor;
    background: #fff;
    font-weight: 600;

  }
  .ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: $blackText;
    background: linear-gradient(90deg, rgba(39, 91, 255, 0.1) 0%, rgba(39, 91, 255, 0) 100%);
    border-radius: 16px 0 0 16px;
  }
  .ant-menu-light .ant-menu-item-selected {
    color: $blackText;
    background: linear-gradient(90deg, rgba(39, 91, 255, 0.1) 0%, rgba(39, 91, 255, 0) 100%);
    border-radius: 16px 0 0 16px;
  }
  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover,
  .ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
    background-color: rgba(0, 0, 0, 0.02);
  }

}
</style>