

const UserRoleMap = {
  0: '所有',
  1: '运营',
  2: '商务主管',
  3: '团长',
  4: '博主',
  5: '审核',
  6: '代发',
  7: '财务',
}


const UserRoleOptions =[]
for (let k in UserRoleMap) {
  UserRoleOptions.push({
    value: k,
    text: UserRoleMap[k],
  })
}

const UserRoleALL = 0
const UserRoleOperator = 1
const UserRoleDirectorManager = 2
const UserRoleDirector = 3
const UserRoleBlogger = 4
const UserRoleAuditor = 5
const UserRoleDaifa = 6
const UserRoleCaiwu = 7

const KeywordStatusMap = {
  '0': '待审核',
  '1': '已通过',
  '3': '已发布',
  '2': '未通过',
  '4': '已失效',
}

const UserStatusMap = {
  '-1': '未激活',
  '0': '待审核',
  '1': '已启用',
  '2': '已拒绝',
  '3': '已冻结',
}

const PaymentMethods = [{name:'支付宝',value:'alipay'},{name:'银行卡',value:'bank'}]


const PromotionTypes = ['公域视频', '直播', '私域']

const UseBooks = {
  "七猫": true,
  "熊猫小说": true,
  "追书神器": true,
  "UC拉新": true,
  "UC付费": true,
  "快看": true,
  "抖音故事": true,
  '河马剧场': true,
  '迅雷小说': true,
}

const ContentCategory = [
  // 小说、软件教程、头像壁纸、绘画、原创壁纸、动漫、漫画、影视
  { value: '小说', },
  { value: '软件教程', },
  { value: '头像壁纸', },
  { value: '绘画', },
  { value: '原创壁纸', },
  { value: '动漫', },
  { value: '漫画', },
  { value: '影视', },
]


const ContentCategoryForUCDisk = [
  {
    value: '动漫',
  },{
    value: '漫画',
  },{
    value: '影视',
  },{
    value: '其他',
  }
]

const UC_INTERVENE_SC = "普通干预"
const UC_INTERVENE_NO_SC = "强制干预第一位"
const UC_INTERVENE_URL = "网页拦截"

const CREATE_RESOURCE_PACKAGE = "CREATE_RESOURCE_PACKAGE"
const payment_finance_audit = "payment_finance_audit"
const payment_finance_submit = "payment_finance_submit"

const PaymentApplyAuditStatus = [
  {
    label: "待审核",
    value: 0,
  }, {
    label: "待审核",
    value: 1,
  }, {
    label: "已通过",
    value: 2,
  }, {
    label: "已拒绝",
    value: 3,
  }
]

const PaymentApplyAuditStatusMap = {
  0: '待提交',
  1: '待审核',
  2: '已通过',
  3: '已拒绝',
}
const PaymentApplyAuditStatusColorMap = {
  0: '',
  1: 'red',
  2: 'green',
  3: 'purple',
}
const PaymentTransactionStatusMap = {
  0: '待处理',
  1: '付款核验中',
  2: '待支付',
  3: '付款成功',
  4: '付款中',
  5: '付款暂停',
  6: '付款失败',
  7: '部分成功',
  10: '付款提交审核',
  11: '审核通过待支付',
  12: '审核拒绝',
}
const PaymentTransactionStatusColorMap = {
  0: '',
  1: '',
  2: '',
  3: '#87d068',
  4: '#f50',
  5: '#faad14',
  6: '#ff4d4f',
  7: 'green',
  10: 'pink',
  11: 'cyan',
  12: 'purple',
}

const ProfitTypes = ['拉新', 'pc端拉新', '转存', '卸载', '拉活', 'cps分成', 'pc端转存', 'pc端cps']

export  {
  ProfitTypes,
  
  PaymentMethods,
  UserRoleMap,
  KeywordStatusMap,

  PromotionTypes,

  UseBooks,
  ContentCategory,
  ContentCategoryForUCDisk,
  

  UC_INTERVENE_SC,
  UC_INTERVENE_NO_SC,
  UC_INTERVENE_URL,

  UserRoleALL,
  UserRoleOperator,
  UserRoleDirectorManager,
  UserRoleDirector,
  UserRoleBlogger,
  UserRoleAuditor,
  UserRoleOptions,
  UserStatusMap,
  UserRoleDaifa,
  UserRoleCaiwu,

  CREATE_RESOURCE_PACKAGE,
  payment_finance_audit,
  payment_finance_submit,

  PaymentApplyAuditStatus,

  PaymentApplyAuditStatusMap,
  PaymentApplyAuditStatusColorMap,

  PaymentTransactionStatusMap,
  PaymentTransactionStatusColorMap,
}

