import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw} from 'vue-router'
import {useStore} from 'vuex'
import MainPageLayout from '@/layout/MainPageLayout.vue'
import request from '@/utils/request'

const routes: Array<RouteRecordRaw> = [
  // 注册/登录等
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login/Index.vue'),
    meta: {
      isAuth: false,
    },
  },
  
  {
    path: '/keywords',
    name: 'Keywords',
    component: MainPageLayout,
    children: [
      // 首页
      {
        path: 'home',
        name: 'Dashboard',
        component: () => import('../views/Dashboard/Index.vue'),
        meta: {
          key: 'home'
        },
      },
      // 推广中心
      {
        path: 'apply',
        name: 'KeywordApply',
        component: () => import('../views/KeywordApply/Index.vue'),
      },
      // 个人设置
      {
        path: 'profile',
        name: 'KeywordProfile',
        component: () => import('../views/KeywordProfile/Index.vue'),
        meta: {
          key: 'basic'
        },
      },
      // 博主管理
      {
        path: 'users',
        name: 'KeywordUsers',
        component: () => import('../views/KeywordUsers/Index.vue'),
        meta: {
          key: 'basic'
        },
      },
      // 博主管理-新版
      {
        path: 'users/bloggers',
        name: 'KeywordUsersBloggers',
        component: () => import('../views/KeywordUsers/IndexBlogger.vue'),
        meta: {
          key: 'basic'
        },
      },
      // 博主详情
      {
        path: 'users/bloggersdetails',
        name: 'BloggerDetail',
        component: () => import('../views/KeywordUsers/BloggerDetail.vue'),
        meta: {
          key: 'basic'
        },
      },
      // 团长管理
      {
        path: 'directors',
        name: 'KeywordDirectors',
        component: () => import('../views/KeywordDirectors/Index.vue'),
        meta: {
          key: 'basic'
        },
      },
      // 关键词每日数据
      {
        path: 'stats_keyword',
        name: 'StatsKeyword',
        component: () => import('../views/StatsKeyword/Index.vue'),
        meta: {
          key: 'stats'
        },
      },
      // 关键词累计数据
      {
        path: 'stats_keyword_sum',
        name: 'StatsKeywordSum',
        component: () => import('../views/StatsKeywordSum/Index.vue'),
        meta: {
          key: 'stats'
        },
      },
      // 博主累计数据
      {
        path: 'stats_data_blogger',
        name: 'StatsBlogger',
        component: () => import('../views/StatsBlogger/Index.vue'),
        meta: {
          key: 'stats'
        },
      },
      // 平台累计数据
      {
        path: 'stats_newer_platform_sum',
        name: 'StatsPlatform',
        component: () => import('../views/StatsPlatform/Index.vue'),
        meta: {
          key: 'stats'
        },
      },
      //团长每日统计
      {
        path: 'stats_data_director_daily',
        name: 'statsDirectorDaily',
        component: () => import('../views/statsDirectorDaily/Index.vue'),
        meta: {
          key: 'stats'
        },
      },
      // 团长累计数据
      {
        path: 'stats_data_director',
        name: 'statsDirector',
        component: () => import('../views/statsDirector/Index.vue'),
        meta: {
          key: 'stats'
        },
      },
      // 结算数据
      {
        path: 'settlement',
        name: 'Settlement',
        component: () => import('../views/Settlement/Index.vue'),
        meta: {
          key: 'stats'
        },
      },
    ],
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/Test.vue'),
    meta: {
      isAuth: false,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      isAuth: false,
    },
  },
  {
    path: '/:catchAll(.*)', // 404 页面
    redirect: '/',
    meta: {
      isAuth: false,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const store = useStore()
  
  if (!store.state.platforms.length) {
    
    request.get(
      '/api/platforms'
    ).then((res: any) => {
      
      const data = res.data?.platforms?.filter(v=>v.status==1)
      const platform:any = []
      data.forEach((v:any)=>{
        platform.push({value:v.platform,label:v.platform})
      })
      
      store.commit("setPlatforms", platform)
      store.commit("setPlatformsData", data)
    }).catch((err) => {
      console.log('err', err);
      
    })
  }
  next()
})

export default router
