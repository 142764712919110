import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      username: '',
      user_id: '0',
      role: '0',
      blogger_name: "",
      blogger: {}
    },
    platforms: [],
    platformsData: [],
  },
  mutations: {
    setLogin: (state, user) => {
      state.user = user
    },
    setPlatforms: (state, platforms) => {
      state.platforms = platforms
    },
    setPlatformsData: (state, platformsData) => {
      state.platformsData = platformsData
    } 
  },
  actions: {
  },
  modules: {
  }
})
