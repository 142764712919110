<template>
  <div class="main-page-layout">
    <a-layout class="main-alayout">
      <a-layout-header :style="headerStyle" style="display:flex;justify-content:space-between;align-items:center;">
        <div class="header-logo">
          <div class="header-logo-image"><img src="@/assets/images/logo.png" /></div>
          <span>火星视界</span>
          
        </div>
        <div>
          <a-alert v-if="isDev" type="warning" show-icon style="padding-top: 10px;padding-bottom: 10px;">
            <template #description>
              「 这是测试页面，正式地址请访问 <a href="http://koc.marsvis.com">koc.marsvis.com</a> 」
            </template>
          </a-alert>
        </div>
        <div style="display: flex;">
          <!-- <div style="margin-right:24px;"><TopMessages /></div>
          <div style="margin-right:24px;display: flex;align-items:center"><HelpDocument /></div> -->
          <span style="font-size: 14px;">当前用户: {{username}}{{ role }}</span>
        </div>
      </a-layout-header>
      <a-layout>
        <a-layout-sider :style="siderStyle"  v-model:collapsed="collapsed" collapsible width="240">
          <div class="main-layout-side">
            <div class="main-layout-navi"><Navi /></div>
            <div class="main-layout-logout" @click="handleLogout">
              <img src="@/assets/images/quit.png" />
              <span>退出登录</span>
            </div>
          </div>
        </a-layout-sider>
        <a-layout-content :style="layoutStyle">
          <!-- <a-alert message="Informational Notes" type="info" show-icon style="border-radius: 0;" /> -->
          <a-layout-content :style="contentStyle">
            <RouterView />
          </a-layout-content>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>
<script lang='ts' setup>
import Navi from '@/components/Navi.vue';
import TopMessages from '@/components/TopMessages.vue';
import HelpDocument from '@/components/HelpDocument.vue';
import {useStore} from 'vuex'
import {reactive,computed,ref} from 'vue'
import request from '@/utils/request'
import {UserRoleMap} from '@/utils/macro.js'

const isDev = ref(window.location.hostname=='koc.dev.marsvis.com')
const collapsed = ref<boolean>(false);
const headerStyle = {
  color: '#fff',
  height: 80,
  paddingInline: '24px',
  lineHeight: '64px',
  backgroundColor: '#275BFF',
};

const contentStyle = {
  backgroundColor: '#F5F7F9',
  padding: '20px 20px 0',
  flex: 'auto',
};

const layoutStyle = {
  display: 'flex',
  flexDirection: 'column',
}

const siderStyle = {
  backgroundColor: '#fff',
};
const store = useStore()
const username = computed(() => store.state.user.username)

const role = computed(() => {
  let roleNum = store.state.user.role
  let label = store.state.user.label
  return roleNum==3&&label=='core'?'（核心团长）':roleNum==2&&label=='leader'?'（商务主管 Leader）':` （${UserRoleMap[roleNum]}）`
})

const handleLogout = () => {
  request.get(`/api/users/logout`).then(() => {
    location.href="/"
  })
}

</script>
<style lang='scss'>
.main-page-layout {
  height: 100%;
  width: 100%;
  .main-alayout {
    height: 100%;
  }
  .header-logo {
    color: #FFF; 
    font-family: YouSheBiaoTiHei;
    font-size: 24px;
    display: flex;
    align-items: center;
    .header-logo-image {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      &>img {
        width: 100%;
      }
    }
  }
  .main-layout-side {
    display: flex;
    flex-direction: column;
    padding: 20px 0 20px 0px;
    height: 100%;
    .main-layout-navi {
      flex: auto;
      height: 100%;
      overflow: auto;
    }
    .main-layout-logout {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      color: $greyText;
      cursor: pointer;
      justify-content: center;
      &>img {
        width: 20px;
        margin-right: 2px;
      }
    }
  }
}
.ant-layout .ant-layout-sider-trigger {
  background-color: rgba(196, 196, 196, 0.4) !important;
  color: #333 !important;
}
</style>