<template>
  <a-config-provider
    :locale="zhCN"
    :theme="{
      token: {
        colorPrimary: '#275BFF',
        borderRadius: 12,
      },
    }"
  >
    <a-style-provider :transformers="[legacyLogicalPropertiesTransformer]" hash-priority="high">
      <router-view />
    </a-style-provider>
  </a-config-provider>
</template>

<script lang="ts" setup>
import {useRouter,useRoute} from 'vue-router'
import { useStore } from 'vuex'
import {ref,onMounted} from 'vue'
import request from '@/utils/request'
import { legacyLogicalPropertiesTransformer } from 'ant-design-vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

const router = useRouter()
const route = useRoute()
const store = useStore()

onMounted(() => {
  request.get(
    '/api/users/auth'
  ).then((res: any) => {
    store.commit("setLogin", res.data)
    // if (route.path == "/" || route.path == '/login') {
    //   router.push({
    //     path: "/keywords/apply"
    //   })
    // }
  }).catch((res) => {
    console.log("未登录", res)
    router.push({
      path: "/login"
    })
  })

  // request.get(
  //   '/api/platforms'
  // ).then((res: any) => {
  //   console.log('res', res.data.platforms);
    
  //   let data = res.data?.platforms?.filter(v=>v.status==1)
  //   let platform = []
  //   data.forEach((v:any)=>{
  //     platform.push({value:v.platform,label:v.platform})
  //   })
  //   console.log('platform', platform);
    
  //   store.commit("setPlatforms", platform)
  //   store.commit("setPlatformsData", data)
  // }).catch((err) => {
  //   console.log('err', err);
    
  // })
})

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}
</style>
